/**
 * Print Stylesheet
*/

@media print {

	.container {
		width: 100%; 
		margin: 0; 
		float: none;
	}

	body, html, .content-container { 
   	display: block !important;
   	height: auto !important;
   	overflow: auto !important;
  }
			     
	body {
		font: 13pt Arial, Times, serif;
		line-height: 1.3;
		background: #fff !important;
	}
	* {
		color: #000;
	}	
	h1, h2, h3, h4, .tags__links a, .breadcrumbs__list-item a {
		color: #000 !important;
	}

	.card--services__content__holder {
		padding-top: 0 !important;
	}
	.content-block, .content-block>.title {
		margin-bottom: 1rem !important;
	}
	.title {
		margin-bottom: 1em !important;
	}
	.content-authors {
		display: flex;
		margin: 0 !important;
	}
	.content-author {
		margin-top: 0 !important;
		margin-right: 2em;
	}
	.content-block {
		margin-top: 2rem;
    margin-bottom: 2rem;
	}
	.tags__links a {
		font-size: 80% !important;
	}
	.cta-block, .social-connect {
		margin: 1em !important;
	}
			
	.masthead, 
	.cta--read-more svg,
	.navigation,
	.navigation-close,
	.footer
	{
		display: none !important;
	}

	.contact-block {
		box-shadow: none !important;
	}

	p, address, li, dt, dd, blockquote {
		font-size: 100%
	}
	a svg {
		fill: #000;
	}
	.profile__social li a, p a[href*='//']:after, .content-author__social {
      content:" (" attr(href) ") ";
  }

  img {
		page-break-inside: avoid;
	}
}